import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'Components/Button/Button';
import { FormattedMessage as Translation } from 'react-intl';
import withTrack from 'HOCs/withTrack/withTrack';
import { setSelectFrom } from 'Helpers/track';
import { configSelector } from 'Selectors/config';
import withConfig from 'HOCs/withConfig/withConfig';
import { getItemTrackingInfo } from 'Helpers/item';
import { videoTagCheck, dealerTagCheck } from '../../../../../app/pages/Listing/utils';
import { getListingType } from 'Helpers/trackingUtils';

import css from './CallButton.APP_TARGET.scss';

const CallButton = ({ trackOrigin, browseMode, track, phoneNumber: callCenterPhone, item, config, user, 'data-aut-id': dataAutId }) => {
    const carsCategory = config.get('adpvAuto', 'carCategory');

    const itemTrackingInfo = useCallback(() => {
        const { id, category_id } = item || {};
        const trackingInfo = {
            item_id: id
        };

        if (category_id === carsCategory) {
            const itemInfo = getItemTrackingInfo(item, user);

            return {
                ...trackingInfo,
                ...itemInfo
            };
        }
        return trackingInfo;
    }, [item, user, carsCategory]);

    const handleCall = useCallback(e => {
        e.preventDefault();
        const trackInfo = {
            origin: trackOrigin,
            select_from: setSelectFrom(trackOrigin),
            browsing_mode: browseMode,
            tag_check: videoTagCheck(item),
            dealer_tag_check: dealerTagCheck(user?.showroom_enabled),
            chosen_option: 'call_now_card',
            listing_type: getListingType(trackOrigin),
            ...itemTrackingInfo()
        };

        track('item_tap_call', trackInfo);

        if (callCenterPhone) {
            document.location.href = `tel://${callCenterPhone}`;
        }
    }, [browseMode, callCenterPhone, trackOrigin, track, itemTrackingInfo, item, user]);

    return (
        <Button
            className={ css.callBtn }
            type="primary"
            onClick={ handleCall }
            data-aut-id={ dataAutId }
            icon={ 'call' }
            size="small"
        >
            <Translation id="call" />
        </Button>
    );
};

CallButton.propTypes = {
    trackOrigin: PropTypes.string,
    browseMode: PropTypes.string,
    track: PropTypes.func.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    user: PropTypes.object,
    'data-aut-id': PropTypes.string
};

CallButton.defaultProps = {
    trackOrigin: '',
    browseMode: '',
    'data-aut-id': 'itemCardCallBtn'
};

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.users.elements[ownProps.item.user_id],
        trackOrigin: state.track.origin,
        generalConfig: configSelector(state),
        browseMode: state.track.browsingMode
    };
};

export default compose(
    withConfig,
    withTrack,
    connect(mapStateToProps, null)
)(CallButton);
