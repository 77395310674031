import React from 'react';
import { FormattedMessage as Translation } from 'react-intl';
import { getFeaturedIntegrationTag } from 'Helpers/item';
import { THEMES } from 'Constants/bundles';
import { FEATURED_PTT_TAG, PTT_TAG } from 'Components/Listing/components/constants';
import css from './ItemCard.scss';

const defaultFeatureTag = <Translation id="listing_ad_featured_label" />;

const renderIntegrationFeatureTag = (tag, theme) => (
    <span
        style={ { background: tag.background_color, color: tag.text_color } }
        className={ theme === THEMES.OLX ? css.olx : '' }
    >
        <span>{tag.tag}</span>
    </span>
);

const renderFeaturedTag = theme => (
    <span className={ theme === THEMES.OLX ? css.olx : '' }>
        {defaultFeatureTag}
    </span>
);

const renderPTT = (theme, staticAssetUrl, ptt) => {
    return (<span className={ theme === THEMES.OLX && css.olx }>
        <img src={ `${staticAssetUrl}${ptt.IMAGE}` } alt={ ptt.ALT } />
        {defaultFeatureTag}</span>
    );
};

export const getStatus = ({ tags, olxAutosTags, showFeaturedTag, previousStatus, previousClass, theme, isSponsored, staticAssetUrl }) => {
    const integrationFeaturetag = getFeaturedIntegrationTag(tags);

    // Integration Feature Tag will always have the priority over normal feature tag
    if (integrationFeaturetag) {
        return { status: (
            <label>
                {olxAutosTags}
                {renderIntegrationFeatureTag(integrationFeaturetag, theme)}
            </label>
        ), statusClass: css.statusTags };
    }
    else if (isSponsored && showFeaturedTag) {
        // Render Featured PTT Tag
        return { status: (
            <label>
                {olxAutosTags}
                {renderPTT(theme, staticAssetUrl, FEATURED_PTT_TAG)}
            </label>
        ), statusClass: css.statusTags };
    }
    else if (isSponsored && !showFeaturedTag) {
        // Render PTT Tag
        return { status: (
            <label>
                {olxAutosTags}
                {renderPTT(theme, staticAssetUrl, PTT_TAG)}
            </label>
        ), statusClass: css.statusTags };
    }
    else if (!!olxAutosTags || showFeaturedTag) {
        return { status: (
            <label>
                {olxAutosTags}
                {showFeaturedTag && renderFeaturedTag(theme)}
            </label>
        ), statusClass: css.statusTags };
    }
    return {
        status: previousStatus,
        statusClass: previousClass };
};

