export function getListingType(origin) {
    return origin === 'dealer_showroom' ? 'showroom' : 'normal';
}
export function getPttSelectFrom(status) {
    return status ? 'pin_to_top' : 'standard';
}

export const getBrandAndModel = (data = []) => {
    let brand = null;
    let model = null;

    (data || []).forEach(item => {
        if (item.key === 'make') {
            brand = item.formatted_value;
        }
        else if (item.key === 'model') {
            model = item.formatted_value;
        }
    });

    return { brand, model };
};
