import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import css from './PolygonRelaxationMessage.APP_TARGET.scss';
import { EXTENDED_LOCATION, LISTING } from 'Constants/tracking';
import { ITEM_SEARCH_RELAXATION_TYPE_POLYGON } from 'Constants/items';
import withTrack from 'HOCs/withTrack/withTrack';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

export class PolygonRelaxationMessage extends React.PureComponent {
    static propTypes = {
        section: PropTypes.object.isRequired,
        selectedLocationName: PropTypes.string.isRequired,
        isCurrentGPSLocation: PropTypes.bool.isRequired,
        intl: PropTypes.shape({
            formatMessage: PropTypes.func.isRequired
        }).isRequired,
        track: PropTypes.func.isRequired
    };

    componentDidMount() {
        const { section, track } = this.props;

        track(EXTENDED_LOCATION, {
            select_from: LISTING,
            place_selected_id: section.location && section.location.id,
            extended_relaxation_type: ITEM_SEARCH_RELAXATION_TYPE_POLYGON
        });
    }

    render() {
        const { section, selectedLocationName, isCurrentGPSLocation, intl } = this.props;

        const nextLocation = section.location && section.location.name;
        const showNoResultMessage = section.offset === 0;

        const noResultsMessageId = isCurrentGPSLocation ? 'relaxationPolygonNoResultsNearYou' : 'relaxationPolygonNoResults';
        const nextResultsMessageId = 'relaxationPolygonDisplayingResults';

        const noResultsMessage = intl.formatMessage({ id: noResultsMessageId }, { location: selectedLocationName });
        const nextResultsMessage = intl.formatMessage({ id: nextResultsMessageId });

        return (
            <React.Fragment>
                {
                    showNoResultMessage && <div id={ noResultsMessageId } className={ css.noResultsMessage }>{ noResultsMessage }</div>
                }
                <div id={ nextResultsMessageId } className={ classNames(css.nextResultsMessage, { [css.messageWithMarginTop]: !showNoResultMessage }) }>
                    { nextResultsMessage }&nbsp;<b>{ nextLocation }</b>
                </div>
            </React.Fragment>
        );
    }
}

export default compose(
    injectIntl,
    withTrack
)(PolygonRelaxationMessage);
