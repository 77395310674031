/* global window */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import css from './CarsPlaceholder.scss';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import { setOrigin } from 'Actions/track';
import { compose } from 'redux';
import withTrack from 'HOCs/withTrack/withTrack';
import classNames from 'classnames';
import withConfig from 'HOCs/withConfig/withConfig';
import { CONFIG } from 'Constants/config';
import { isMobile } from 'Helpers/devices';
import { DATA_AUT_IDS } from 'Constants/constants';
import { PROMO_CARD_CLICK, CAR_CATEGORY } from 'Constants/tracking';
import { useEffect } from 'react';

export const CarsPlaceholder = props => {
    const siteCode = props.config.get(CONFIG.SITE_CODE);
    const staticAssetUrl = props.config.get(CONFIG.STATIC_ASSETS);
    const autosUrl = props.config.get(CONFIG.AUTOS_BANNER, CONFIG.START_SELLING_BANNER_REDIRECTION_OLX_AUTOS);
    const showModernDesign = props.config.get(CONFIG.SHOW_MODERN_DESIGN);
    const [autosImage, setAutosImage] = useState();

    const tabMinWidth = 540;
    const tabsMaxWidth = 1024;

    const getScreenWidth = () => {
        return window?.innerWidth >= tabMinWidth && window?.innerWidth <= tabsMaxWidth;
    };

    useEffect(() => {
        const getScreenName = () => {
            if (showModernDesign && getScreenWidth()) {
                return CONFIG.TAB;
            }
            else if (isMobile) {
                return CONFIG.MOBILE;
            }
            return CONFIG.DESKTOP;
        };

        if (typeof window !== 'undefined' && window) {
            const autosBannerImage = `${staticAssetUrl}/${siteCode}/${CONFIG.BANNER_URL}${getScreenName()}.png`;

            setAutosImage(autosBannerImage);
        }
    }, [showModernDesign, siteCode, staticAssetUrl]);

    const redirect = useCallback(() => {
        const trackProps = {
            select_from: CAR_CATEGORY,
            chosen_option: props.placeholderPosition,
            category_id: props.items[0]?.category_id
        };

        props.setOriginTrack(trackProps.select_from, trackProps.chosen_option);
        props.track(PROMO_CARD_CLICK, trackProps);

        window.open(autosUrl, CONFIG.BLANK);
    }, [props, autosUrl]);

    if (!props.shouldRun) {
        return null;
    }

    return (
        <li className={ classNames(css.carsPlaceholder, props.className) }
            onClick={ redirect }
            data-aut-id={ DATA_AUT_IDS.CARS_PLACEHOLDER }>
            <img src={ autosImage } alt={ CONFIG.AUTOS_BANNER_IMAGE } className={ showModernDesign ? css.autosBannerImage : css.autosBannerImageMobile } />
        </li>
    );
};

CarsPlaceholder.propTypes = {
    setOriginTrack: PropTypes.func,
    track: PropTypes.func,
    config: PropTypes.object,
    className: PropTypes.string,
    placeholderPosition: PropTypes.string,
    items: PropTypes.array,
    shouldRun: PropTypes.bool
};

CarsPlaceholder.defaultProps = {
    config: {},
    className: '',
    placeholderPosition: '',
    items: {}
};

export const mapStateToProps = () => {
    return {};
};

export const mapDispatchToProps = dispatch => {
    return {
        setOriginTrack: origin => dispatch(setOrigin(origin))
    };
};

export default compose(
    withRouter,
    withTrack,
    withConfig,
    connect(mapStateToProps, mapDispatchToProps)
)(CarsPlaceholder);
