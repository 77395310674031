import React from 'react';
import PropTypes from 'prop-types';
import css from './Placeholder.scss';
import classNames from 'classnames';

const Placeholder = ({ visualizationType, className, placeholderImagesStyle }) => (
    <li className={ classNames(css[`${visualizationType}Placeholder`], className) } >
        <div className={ classNames(css.image, css.animatedBackground, placeholderImagesStyle) } />
        <div className={ css.text }>
            <span className={ css.animatedBackground } />
            <span className={ css.animatedBackground } />
        </div>
    </li>
);

Placeholder.propTypes = {
    visualizationType: PropTypes.oneOf(['grid', 'big', 'list', 'myAds']),
    className: PropTypes.string,
    placeholderImagesStyle: PropTypes.string
};

Placeholder.defaultProps = {
    visualizationType: 'grid',
    className: '',
    placeholderImagesStyle: ''
};

export default Placeholder;
