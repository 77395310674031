import React from 'react';
import PropTypes from 'prop-types';
import css from './SectionTitle.APP_TARGET.scss';
import { selectedLocationNameSelector, isCurrentGPSLocationSelector } from 'Selectors/location';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ITEM_SEARCH_RELAXATION_TYPE_BUCKET } from 'Constants/items';
import BucketRelaxationMessage from './BucketRelaxationMessage';
import PolygonRelaxationMessage from './PolygonRelaxationMessage';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

export const SectionTitle = ({ ...props }) => {
    const { section } = props;

    const relaxationMessage = section.relaxation_type === ITEM_SEARCH_RELAXATION_TYPE_BUCKET
        ? <BucketRelaxationMessage { ...props } />
        : <PolygonRelaxationMessage { ...props } />;

    return (
        <li data-aut-id="itemTitle"
            className={ classNames({ [css.newSectionTitle]: props.currentPage > 1 }, css.sectionTitle) }>
            { relaxationMessage }
        </li>
    );
};

SectionTitle.propTypes = {
    section: PropTypes.object.isRequired,
    currentPage: PropTypes.number
};

SectionTitle.defaultProps = {
};

export const mapStateToProps = (state, props) => ({
    selectedLocationName: selectedLocationNameSelector(state, props),
    isCurrentGPSLocation: isCurrentGPSLocationSelector(state)
});

export default compose(
    injectIntl,
    connect(mapStateToProps)
)(SectionTitle);
