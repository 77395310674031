import React from 'react';
import PropTypes from 'prop-types';
import css from './ItemImage.scss';
import { css as uicss } from 'panamera-react-ui';
import ImageLoader from './ImageLoader';
import ReactObserver from 'Components/ReactObserver/ReactObserver';
import { LAZYLOAD_OFFSET_VERTICAL } from './constants';
import classNames from 'classnames';
import { QUALITY_IMAGE_60 } from 'Constants/images';
import { isMediaverseImage } from '../../../../helpers/images';

const { icons } = uicss;

export class ItemImage extends React.PureComponent {
    static propTypes = {
        image: PropTypes.shape({
            url: PropTypes.string,
            width: PropTypes.number,
            height: PropTypes.number
        }).isRequired,
        alt: PropTypes.string,
        defaultSize: PropTypes.string,
        friendsInCommon: PropTypes.bool,
        visualizationType: PropTypes.string.isRequired,
        listingType: PropTypes.string,
        'data-aut-id': PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number,
        isCarImage: PropTypes.bool
    };

    static defaultProps = {
        defaultSize: 's=300x0',
        friendsInCommon: false,
        alt: '',
        listingType: 'items',
        'data-aut-id': 'itemImage',
        isCarImage: false
    };

    constructor(props) {
        super(props);

        this.state = {
            imageStatus: false,
            src: ''
        };
    }

    handleImageLoaded = () => {
        this.setState({ imageStatus: true });
    };

    getSizes = () => {
        const { visualizationType, listingType } = this.props;

        if (visualizationType === 'grid') {
            return '(min-width: 1280px) 15vw, (min-width: 1024px) 20vw, (min-width: 960px) 25vw, (min-width: 540px) 25vw, (min-width: 320px) 30vw, 35vw';
        }
        else if (visualizationType === 'list') {
            return '(min-width: 1280px) 20vw, (min-width: 1024px) 20vw, (min-width: 960px) 25vw, (min-width: 540px) 25vw, (min-width: 320px) 25vw, 35vw';
        }
        else if (listingType === 'reProjects') {
            return null;
        }
        return '(min-width: 1280px) 100vw, (min-width: 1024px) 20vw, (min-width: 960px) 25vw, (min-width: 540px) 25vw, (min-width: 320px) 30vw, 35vw';
    };

    itemImageComponent = (imageContent, friendsClass, visualizationType, dataAutid) => ({ inView, ref }) => (
        <figure ref={ ref } className={ classNames(css.itemImage, css[visualizationType], friendsClass) } data-aut-id={ dataAutid }>
            <ImageLoader loaded={ this.state.imageStatus }>
                { (inView || this.state.imageStatus) ? imageContent : null }
            </ImageLoader>
            <noscript>{ imageContent }</noscript>
        </figure>
    );

    render() {
        const { image, defaultSize, friendsInCommon, alt, visualizationType, 'data-aut-id': dataAutid, listingType, height, width, isCarImage } = this.props;
        const isAkamaizedImage = isMediaverseImage(image);
        const friendsClass = friendsInCommon ? `${icons['icon-Friends']} ${icons.panameraIcons}` : '';
        let srcSet;
        let src;
        let imageClassName;
        const sizes = this.getSizes();

        if (isAkamaizedImage) {
            src = `${image.url};${defaultSize}`;
            srcSet = `${ image.url };s=100x200;q=${ QUALITY_IMAGE_60 } 100w,
                    ${ image.url };s=200x400;q=${ QUALITY_IMAGE_60 } 200w,
                    ${ image.url };s=300x600;q=${ QUALITY_IMAGE_60 } 300w,
                    ${ image.url };s=400x800;q=${ QUALITY_IMAGE_60 } 400w,
                    ${ image.url };s=600x1200;q=${ QUALITY_IMAGE_60 } 600w`;
        }
        else {
            src = image.url;
        }

        const widthtImage = image.width > image.height;

        if (visualizationType === 'list') {
            imageClassName = widthtImage ? css.listWidth : css.listHeight;
        }
        else {
            imageClassName = widthtImage ? css.width : css.height;
        }

        const imageContent = (<img
            src={ src }
            alt={ alt }
            sizes={ sizes }
            srcSet={ srcSet }
            className={ classNames(imageClassName, { [css.projectImage]: listingType === 'reProjects' }, { [css.carImage]: isCarImage }) }
            onLoad={ this.handleImageLoaded }
            height={ height }
            width={ width }
        />);

        return (
            <ReactObserver rootMargin={ `${LAZYLOAD_OFFSET_VERTICAL}px` }>
                { this.itemImageComponent(imageContent, friendsClass, visualizationType, dataAutid) }
            </ReactObserver>
        );
    }
}

export default ItemImage;
