import React, { useCallback } from 'react';
import Auth from '../../../Auth';
import PropTypes from 'prop-types';
import css from './ItemPostPlaceholder.scss';
import Button from 'Components/Button/Button';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import { FormattedMessage as Translation } from 'react-intl';
import setLoginModalState from 'Actions/setLoginModalState';
import { setOrigin } from 'Actions/track';
import { compose } from 'redux';
import withTrack from 'HOCs/withTrack/withTrack';
import classNames from 'classnames';

const ItemPostPlaceholder = props => {
    const postAd = useCallback(() => {
        const trackProps = {
            origin: props.trackerOrigins.PLACEHOLDER
        };

        props.setOriginTrack(trackProps.origin);
        props.track('posting_tap_post', trackProps);

        if (Auth.isLoggedIn()) {
            props.router.push('/post');
        }
        else {
            props.setLoginModalState({ state: true, redirectTo: '/post', trackProps: { origin: 'posting' }});
        }
    }, [props]);

    return (
        <li className={ classNames(css.itemPostPlaceholder, css[props.visualizationType], props.className, {
            [css.withoutVas]: props.hideVasStripForPP
        }) }
        onClick={ postAd }>
            <div className={ css.content }>
                <div className={ css.text }>
                    <h3 className={ css.title }>
                        <Translation id="placeholderTitle" tagName="div" />
                    </h3>
                    <div className={ css.subTitle }>
                        <Translation id="placeholderBody" tagName="div" />
                    </div>
                </div>
                <div className={ css.buttonContainer }>
                    <Button type="secondaryBtn" size="small" fixed invert>
                        <Translation id="placeholderCTA" tagName="div" />
                    </Button>
                </div>
            </div>
        </li>
    );
};

ItemPostPlaceholder.propTypes = {
    setOriginTrack: PropTypes.func,
    router: PropTypes.object,
    setLoginModalState: PropTypes.func,
    visualizationType: PropTypes.string,
    track: PropTypes.func,
    trackerOrigins: PropTypes.object,
    className: PropTypes.string,
    hideVasStripForPP: PropTypes.bool
};

ItemPostPlaceholder.defaultProps = {
    visualizationType: 'grid',
    className: ''
};

export const mapStateToProps = () => {
    return {};
};

export const mapDispatchToProps = dispatch => {
    return {
        setOriginTrack: origin => dispatch(setOrigin(origin)),
        setLoginModalState: state => dispatch(setLoginModalState(state))
    };
};

export { ItemPostPlaceholder };
export default compose(
    withRouter,
    withTrack,
    connect(mapStateToProps, mapDispatchToProps)
)(ItemPostPlaceholder);
