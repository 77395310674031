import { CONFIG as listConfig } from 'Constants/config';

export const isAutosAvailable = (config, items, id) => {
    const autosConfig = config.get(listConfig.OLX_AUTOS, listConfig.ALL_CAR_CATEGORIES);
    const isAutosBannerEnabled = config.get(listConfig.AUTOS_BANNER, listConfig.IS_ENABLED);
    const autosLocations = config.get(listConfig.AUTOS_BANNER, listConfig.AUTOS_OPERATIONAL_LOCATION) || [];
    const showModernDesign = config.get(listConfig.SHOWMODERDESIGN);
    const carCategory = autosConfig?.includes(items?.[0]?.category_id);

    if (showModernDesign && isAutosBannerEnabled && carCategory) {
        return true;
    }
    else if (isAutosBannerEnabled && autosLocations.includes(id) && carCategory) {
        return true;
    }
    return false;
};

export const isOLXAutosOperationalRegion = (config, locationId) => {
    const olxAutosOperationalLocations = config.get(listConfig.AUTOS_BANNER, listConfig.AUTOS_OPERATIONAL_LOCATION) || [];

    return olxAutosOperationalLocations.includes(locationId);
};
